import React from "react"
import { Link } from "gatsby"
import moment from "moment/moment"

const PostFooterMeta = ({ author, date, categories, tags, uri }) => (
    <footer className="post-footer entry-footer">
        <div className="metadata">
            <ul>
                <li className="meta_author">
                    <span className="screen-reader-text">By</span>
                    <span className="author vcard">
                        <Link
                            className="url fn n"
                            to={`/blog/author/${author.slug}`}
                        >
                            {author.name}
                        </Link>
                    </span>
                </li>
                <li className="meta_date">
                    <Link to={`/blog/${uri}`} rel="bookmark">
                        <time
                            className="entry-date published updated"
                            dateTime={moment(date).format()}
                        >
                            {moment(date).format(`MMMM D, YYYY`)}
                        </time>
                    </Link>
                </li>
                <li className="meta_categories">
                    {categories.nodes
                        .map(category => (
                            <Link
                                key={category.name}
                                to={`/blog/category/${category.slug}`}
                                rel="category"
                            >
                                {category.name}
                            </Link>
                        ))
                        .reduce((accu, elem) => {
                            return accu === null ? [elem] : [...accu, " ", elem]
                        }, null)}
                </li>
                {/* <li className="meta_tags">
                    <span className="screen-reader-text">Tags: </span>
                    {tags.nodes
                        .map(tag => (
                            <Link
                                key={tag.name}
                                to={`/blog/tag/${tag.slug}`}
                                rel="tag"
                            >
                                {tag.name}
                            </Link>
                        ))
                        .reduce((accu, elem) => {
                            return accu === null
                                ? [elem]
                                : [...accu, ", ", elem]
                        }, null)}
                </li> */}
            </ul>
        </div>
    </footer>
)

export default PostFooterMeta
