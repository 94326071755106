import React from "react"
import { Link } from "gatsby"
import PostFooterMeta from "./post-footer-meta"
import Img from "gatsby-image"

const PostEntry = ({
    post: {
        id,
        postId,
        title,
        excerpt,
        content,
        featuredImage,
        uri,
        author,
        date,
        categories,
        tags,
    },
}) => {
    // getting the excerpt to a variable
    let excerptText = excerpt

    // if excerpt does not exist
    if (!excerptText) {
        // getting the first 240 characters off content
        excerptText = content.substr(0, 240)

        // so that a word is not chopped off halfway
        excerptText = content
            .substr(
                0,
                Math.min(excerptText.length, excerptText.lastIndexOf(" "))
            )
            .concat("...")
    }
    return (
        <article
            className="post type-post status-publish format-standard hentry entry"
            data-id={id}
            id={`post-${postId}`}
        >
            {featuredImage && (
                <div className="post-image">
                    <Link to={`/blog/${uri}`} rel="bookmark" aria-label={title}>
                        <Img
                            fluid={
                                featuredImage.imageFile.childImageSharp.fluid
                            }
                        />
                    </Link>
                </div>
            )}

            <header className="post-header entry-header">
                <h2 className="post-title entry-title">
                    <Link
                        dangerouslySetInnerHTML={{ __html: title }}
                        to={`/blog/${uri}`}
                    />
                </h2>
            </header>
            <div className="entry-content">
                <div dangerouslySetInnerHTML={{ __html: excerptText }} />
                <div className="read-more-wrap">
                    <Link
                        to={`/blog/${uri}`}
                        className="more-link read-more secondary-button"
                    >
                        Read More
                    </Link>
                </div>
            </div>
            <PostFooterMeta
                date={date}
                author={author}
                categories={categories}
                tags={tags}
                uri={uri}
            />
        </article>
    )
}

export default PostEntry
